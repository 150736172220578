import { RouteRecordRaw } from "vue-router";

import CompaniesList from "@/modules/companies/CompaniesList";
import { LINKS } from "@/constants/links";
import CompaniesForm from "@/modules/companies/CompaniesForm";

export const COMPANY_LIST_ROUTE: RouteRecordRaw = {
  path: "/companies",
  component: CompaniesList,
  name: "Компании",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.COMPANIES],
  },
};

export const COMPANY_FORM_ROUTE: RouteRecordRaw = {
  path: "/companies/:id",
  component: CompaniesForm,
  name: "Форма компании",
  meta: {
    getBreadcrumbs: (route) => [
      LINKS.HOME,
      LINKS.COMPANIES,
      {
        url: route.path,
        text: "Форма компании",
      },
    ],
  },
};
