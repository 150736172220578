<template>
  <Page
    title="Продукты"
    :header-buttons="[
      {
        text: 'Новый продукт',
        href: getProductsCreateUrl(),
      },
    ]"
  >
    <DataTable
      :column-defs="columnDefs"
      :row-data="rowData"
      :loading="isRowDataLoading"
      :error-message="errorMessage"
      :search-query="searchQuery"
      :pagination="{
        pageNumber,
        pageCount,
        pageSize,
        disabled: isRowDataLoading,
      }"
      @change="handleChange"
    >
      <template #filters>
        <ProductsListSearch
          v-model:loading="searchLoading"
          v-model:value="filterParams"
          :fetch-data="fetchEntityList"
        />
      </template>

      <template #cell(percent)="{ row }">
        <div class="percent-cell">
          <table v-if="row.extendedPercents && row.extendedPercents.length">
            <thead>
              <tr>
                <th>Возраст</th>
                <th>Ставка</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in row.extendedPercents" v-key="item.minAge">
                <td>{{ item.minAge }} - {{ item.maxAge }}</td>
                <td>{{ item.percent }} %</td>
              </tr>
            </tbody>
          </table>
          <div v-else>
            <span class="percent-cell__value">{{ row.percent }} %</span>
            <div
              v-if="row.discountPeriod || row.discountPercent"
              class="percent-cell__discount"
            >
              {{ row.discountPercent }}% первые {{ row.discountPeriod }} мес.
            </div>
          </div>
        </div>
      </template>
      <template #cell(actions)="{ row }">
        <BaseButton
          variant="icon"
          title="Редактировать"
          :disabled="isBusy()"
          :href="getProductsUpdateUrl(row.id)"
        >
          <EditIcon />
        </BaseButton>
        <BaseButton
          variant="icon"
          title="Удалить"
          :disabled="isBusy(row.id)"
          @click="handleResourceDelete(row.id)"
        >
          <DeleteIcon />
        </BaseButton>
      </template>
    </DataTable>
  </Page>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

import {
  useDataTable,
  DataTable,
  DeleteIcon,
  BaseButton,
  EditIcon,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { useResourceDelete } from "@tager/admin-services";

import {
  getProductsCreateUrl,
  getProductsUpdateUrl,
} from "@/modules/products/links";
import { deleteProduct, getProductsList } from "@/modules/products";
import { ProductInterface } from "@/modules/products/typings";

import ProductsListSearch from "./ProductsList.search.vue";
import { COLUMN_DEFS } from "./ProductsList.helpers";

export default defineComponent({
  name: "ProductsList",
  components: {
    ProductsListSearch,
    DataTable,
    Page,
    BaseButton,
    EditIcon,
    DeleteIcon,
  },
  setup() {
    const filterParams = ref<Record<string, string | string[]>>({});
    const searchLoading = ref<boolean>(true);

    const {
      fetchEntityList,
      isLoading: isListDataLoading,
      rowData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<ProductInterface>({
      fetchEntityList: (params) =>
        getProductsList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          ...filterParams.value,
        }),
      initialValue: [],
      resourceName: "Список продуктов",
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteProduct,
      resourceName: "Delete Product",
      onSuccess: fetchEntityList,
    });

    function isBusy(id: number): boolean {
      return isDeleting(id) || isListDataLoading.value;
    }

    return {
      fetchEntityList,
      filterParams,
      searchLoading,
      columnDefs: COLUMN_DEFS,
      rowData,
      isRowDataLoading: isListDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      isBusy,
      getProductsCreateUrl,
      getProductsUpdateUrl,

      handleResourceDelete,
    };
  },
});
</script>

<style scoped lang="scss">
.percent-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  &__value {
    font-size: 16px;
    font-weight: bold;
  }
  &__discount {
    margin-top: 0.5rem;
    font-style: italic;
    font-size: 14px;
  }
}
</style>
