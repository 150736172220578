<template>
  <NavigationGrid :nav-items="navItemList" />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";

import { NavigationGridItem, NavigationGrid } from "@tager/admin-ui";
import { useResource } from "@tager/admin-services";

import { LINKS } from "@/constants/links";
import { getCompaniesCount } from "@/modules/companies/requests";
import { getProductsCount } from "@/modules/products";

export default defineComponent({
  name: "HomePage",
  components: {
    NavigationGrid,
  },
  setup() {
    const [
      fetchCompaniesCount,
      { data: companiesCountData, status: companiesCountDataStatus },
    ] = useResource({
      fetchResource: getCompaniesCount,
      resourceName: "Количество компаний",
      initialValue: null,
    });

    const [
      fetchProductsCount,
      { data: productsCountData, status: productsCountDataStatus },
    ] = useResource({
      fetchResource: getProductsCount,
      resourceName: "Количество продуктов",
      initialValue: null,
    });

    onMounted(() => {
      fetchCompaniesCount();
      fetchProductsCount();
    });

    const navItemList = computed<Array<NavigationGridItem>>(() => {
      return [
        {
          name: LINKS.PRODUCTS.text,
          url: LINKS.PRODUCTS.url,
          total: {
            status: productsCountDataStatus.value,
            value: productsCountData.value?.count ?? 0,
          },
          width: 50,
        },
        {
          name: LINKS.COMPANIES.text,
          url: LINKS.COMPANIES.url,
          total: {
            status: companiesCountDataStatus.value,
            value: companiesCountData.value?.count ?? 0,
          },
          width: 50,
        },
        {
          name: "Администраторы",
          linkList: [LINKS.ADMIN_ADMINS, LINKS.ADMIN_ROLES],
          width: 50,
        },
      ];
    });

    return {
      navItemList,
    };
  },
});
</script>

<style scoped></style>
