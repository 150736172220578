<template>
  <PageLayout :sidebar-menu-list="sidebarMenuList">
    <router-view />
  </PageLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { MenuItemType, PageLayout } from "@tager/admin-layout";
import { HomeIcon, SettingsIcon, ViewListIcon } from "@tager/admin-ui";

import { LINKS } from "@/constants/links";

export default defineComponent({
  name: "App",
  components: { PageLayout },
  setup() {
    const sidebarMenuList: Array<MenuItemType> = [
      { id: "home", icon: HomeIcon, ...LINKS.HOME },
      { id: "products", icon: ViewListIcon, ...LINKS.PRODUCTS },
      { id: "companies", icon: ViewListIcon, ...LINKS.COMPANIES },
      {
        id: "admins",
        text: "Администраторы",
        icon: SettingsIcon,
        children: [LINKS.ADMIN_ADMINS, LINKS.ADMIN_ROLES],
      },
    ];

    return {
      sidebarMenuList,
    };
  },
});
</script>

<style scoped lang="scss"></style>
