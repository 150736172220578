<template>
  <Page
    title="Компании"
    :header-buttons="[
      {
        text: 'Новая компания',
        href: getCompaniesCreateUrl(),
      },
    ]"
  >
    <DataTable
      :column-defs="columnDefs"
      :row-data="rowData"
      :loading="isRowDataLoading"
      :error-message="errorMessage"
      :search-query="searchQuery"
      :pagination="{
        pageNumber,
        pageCount,
        pageSize,
        disabled: isRowDataLoading,
      }"
      @change="handleChange"
    >
      <template #cell(relations)="{ row }">
        <div class="relations">
          <router-link
            :to="{
              path: getProductsListUrl(),
              query: { 'filter[company]': row.id },
            }"
          >
            <CountButton
              variant="outline-secondary"
              :count="row.productsCount"
              class="margin-right"
              :disabled="row.productsCount === 0"
            >
              Продукты
            </CountButton>
          </router-link>
        </div>
      </template>

      <template #cell(actions)="{ row }">
        <BaseButton
          variant="icon"
          title="Редактировать"
          :disabled="isBusy()"
          :href="getCompaniesUpdateUrl(row.id)"
        >
          <EditIcon />
        </BaseButton>
        <BaseButton
          variant="icon"
          title="Удалить"
          :disabled="isBusy(row.id) || !row.canDelete"
          @click="handleResourceDelete(row.id)"
        >
          <DeleteIcon />
        </BaseButton>
      </template>
    </DataTable>
  </Page>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  useDataTable,
  DataTable,
  DeleteIcon,
  BaseButton,
  CountButton,
  EditIcon,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { useResourceDelete } from "@tager/admin-services";

import {
  getCompaniesCreateUrl,
  getCompaniesUpdateUrl,
} from "@/modules/companies/links";
import { CompanyInterface } from "@/modules/companies/typings";
import { deleteCompany, getCompaniesList } from "@/modules/companies/requests";
import { getProductsListUrl } from "@/modules/products/links";

import { COLUMN_DEFS } from "./CompaniesList.helpers";

export default defineComponent({
  name: "BrandList",
  components: {
    DataTable,
    Page,
    BaseButton,
    CountButton,
    EditIcon,
    DeleteIcon,
  },
  setup() {
    const {
      fetchEntityList,
      isLoading: isListDataLoading,
      rowData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<CompanyInterface>({
      fetchEntityList: (params) =>
        getCompaniesList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Список компаний",
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteCompany,
      resourceName: "Delete Company",
      onSuccess: fetchEntityList,
    });

    function isBusy(id: number): boolean {
      return isDeleting(id) || isListDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData,
      isRowDataLoading: isListDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      isBusy,
      getCompaniesCreateUrl,
      getCompaniesUpdateUrl,
      getProductsListUrl,

      handleResourceDelete,
    };
  },
});
</script>

<style scoped lang="scss"></style>
