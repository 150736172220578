<template>
  <Page :title="pageTitle" :is-content-loading="isContentLoading">
    <form v-if="!isContentLoading" novalidate @submit.prevent>
      <FormField
        v-model:value="values.name"
        name="name"
        label="Имя"
        :error="errors.name"
      />
      <FormFieldFileInput
        v-model:value="values.logo"
        name="logo"
        label="Логотип"
        :error="errors.logo"
      />
      <FormField
        v-model:value="values.email"
        name="email"
        label="E-Mail"
        :error="errors.email"
      />
      <FormField
        v-model:value="values.phone"
        name="phone"
        label="Телефон"
        :error="errors.phone"
      />
    </form>
    <template #footer>
      <FormFooter
        :is-creation="isCreation" :can-create-another="isCreation"
        :back-href="backButtonUrl"
        :is-submitting="isSubmitting"
        @submit="submitForm"
      />
    </template>
  </Page>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  Nullable,
  useResource,
  useToast,
} from "@tager/admin-services";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormField,
  FormFieldFileInput,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { CompanyFullInterface } from "@/modules/companies/typings";
import {
  createCompany,
  getCompany,
  updateCompany,
} from "@/modules/companies/requests";
import {
  getCompaniesListUrl,
  getCompaniesUpdateUrl,
} from "@/modules/companies/links";

import {
  FormValues,
  convertCompanyToFormValues,
  convertFormValuesToCompanySavePayload,
} from "./CompaniesForm.helpers";

export default defineComponent({
  name: "CompaniesForm",
  components: {
    Page,
    FormFooter,
    FormField,
    FormFieldFileInput,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed<string>(() => route.params.id as string);
    const isCreation = computed(() => id.value === "create");

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource<
      Nullable<CompanyFullInterface>
    >({
      fetchResource: () => getCompany(id.value),
      initialValue: null,
      resourceName: "Компания",
    });

    const values = ref<FormValues>(convertCompanyToFormValues(model.value));

    const errors = ref<Record<string, string>>({});
    const isSubmitting = ref<boolean>(false);

    onMounted(() => {
      if (route.params.id !== "create") {
        fetchModel();
      }
    });

    watch(model, () => {
      values.value = convertCompanyToFormValues(model.value);
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const savePayload = convertFormValuesToCompanySavePayload(values.value);

      const requestPromise = isCreation.value
        ? createCompany(savePayload)
        : updateCompany(id.value, savePayload);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getCompaniesUpdateUrl(response.data.id));
          } else if (
            event.type === "create_exit" ||
            event.type === "save_exit"
          ) {
            router.push(getCompaniesListUrl());
          } else if (event.type === "create_create-another") {
            values.value = convertCompanyToFormValues(null);
          }

          toast.show({
            variant: "success",
            title: "Success",
            body: "Компания успешно сохранена",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Ошибка",
            body: "Ошибка при сохранении компании",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isContentLoading = computed<boolean>(() => isModelLoading.value);

    const pageTitle = computed<string>(() => {
      return isCreation.value
        ? `Новая компания`
        : `Редактирование компании ${
            model.value?.name ? '"' + model.value.name + '"' : ""
          }`;
    });

    return {
      model,

      pageTitle,
      isContentLoading,
      values,
      errors,
      isCreation,

      submitForm,
      backButtonUrl: getCompaniesListUrl(),
      isSubmitting,
    };
  },
});
</script>
