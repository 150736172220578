import { ColumnDefinition } from "@tager/admin-ui";

import { getProductsUpdateUrl } from "@/modules/products/links";
import { getProductTypeLabel } from "@/modules/products/enums";

import { ProductInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<ProductInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "80px", textAlign: "center" },
    headStyle: { width: "80px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Имя",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: getProductsUpdateUrl(row.id),
      },
      paramList: [
        { name: "Валюта", value: row.currency },
        {
          name: "Тип автомобиля",
          value:
            (row.forAmp && row.forNew
              ? "Все авто"
              : row.forNew
              ? "Новые автомобили"
              : row.forAmp
              ? "Автомобили с пробегом"
              : "") + (row.forElectric ? " (Электромобили)" : ""),
        },
      ],
    }),
  },
  {
    id: 3,
    name: "Компания",
    field: "company",
    format: ({ row }) => row.company?.name || "",
    width: "250px",
  },
  {
    id: 3,
    name: "Тип",
    field: "type",
    format: ({ row }) => getProductTypeLabel(row.type) ,
    width: "100px",
  },
  {
    id: 3,
    name: "Ставка",
    field: "percent",
    width: "220px",
    headStyle: {
      textAlign: "center",
    },
  },
  {
    id: 7,
    name: "Действия",
    field: "actions",
    width: "104px",
  },
];
