import { MinMaxValue, OptionType } from "@tager/admin-ui";
import { FieldConfigUnion, RepeaterField } from "@tager/admin-dynamic-field";

import { ProductFullInterface } from "@/modules/products/typings";
import { ProductSavePayload } from "@/modules/products/requests";
import {
  CollateralType,
  Currency,
  PaymentType,
  ProductCarElectricType,
  ProductCarType,
  ProductCarTypeOptions,
  ProductType,
} from "@/modules/products/enums";

export interface FormValues {
  name: string;
  company: OptionType<number> | null;
  type: ProductType | null;
  carTypes: Array<OptionType<ProductCarType>>;
  carElectricType: ProductCarElectricType;
  currency: Currency | null;
  percent: number | null;

  carAge: MinMaxValue;
  amount: MinMaxValue;
  period: MinMaxValue;

  percentDependsOnAge: boolean;

  discountEnabled: boolean;
  discountPeriod: number | null;
  discountPercent: number | null;

  advancePaymentMin: number | null;
  earlyPaymentMonthsMin: number | null;
  coefficientSpeedUp: number | null;
  redemptionPercent: number | null;

  paymentType: PaymentType;
  collateralType: CollateralType;
  kaskoRequired: boolean;

  conditions: string;
  generations: Array<OptionType<number>>;
}

export function convertProductToFormValues(
  model: ProductFullInterface | null,
  generationsOptions: Array<OptionType<number>>
): FormValues {
  return {
    name: model?.name || "",
    company: model?.company
      ? {
          value: model.company.id,
          label: model.company.name,
        }
      : null,
    type: model?.type || ProductType.Credit,

    carElectricType: model?.forElectric
      ? ProductCarElectricType.Electric
      : ProductCarElectricType.All,

    carTypes: ProductCarTypeOptions.filter((item) => {
      return (
        (item.value === ProductCarType.Amp && model?.forAmp) ||
        (item.value === ProductCarType.New && model?.forNew)
      );
    }),
    currency: model?.currency || Currency.BYN,
    percent: model?.percent || null,

    discountEnabled: Boolean(model?.discountPeriod || model?.discountPercent),
    discountPeriod: model?.discountPeriod || null,
    discountPercent: model?.discountPercent || null,

    carAge: model?.carAge || null,
    amount: model?.amount || null,
    period: model?.period || null,

    advancePaymentMin: model?.advancePaymentMin || null,
    earlyPaymentMonthsMin: model?.earlyPaymentMonthsMin || null,

    coefficientSpeedUp: model?.coefficientSpeedUp || null,
    redemptionPercent: model?.redemptionPercent || null,

    paymentType: model?.paymentType || PaymentType.Annuity,
    collateralType: model?.collateralType || CollateralType.CarAndSurety,
    kaskoRequired: model ? model.kaskoRequired : true,

    percentDependsOnAge: model?.extendedPercents
      ? model.extendedPercents.length > 0
      : false,

    conditions: model?.conditions || "",
    generations: generationsOptions.filter((item) =>
      model?.generations?.map(item => item.id).includes(item.value)
    ),
  };
}

export function convertFormValuesToProductSavePayload(
  values: FormValues,
  extendedPercents: RepeaterField
): ProductSavePayload {
  const forAmp = !!values.carTypes.find(
    (item) => item.value === ProductCarType.Amp
  );

  const isLeasing = values.type === ProductType.Leasing;
  const extendedPercentsEnabled = forAmp && values.percentDependsOnAge;

  const extendedPercentsValue =
    extendedPercentsEnabled && extendedPercents.value
      ? extendedPercents.value.map((item) => {
          return {
            minAge: item.value[0].value ? +item.value[0].value : null,
            maxAge: item.value[1].value ? +item.value[1].value : null,
            percent: item.value[2].value ? +item.value[2].value : null,
            discountPercent: item.value[3].value ? +item.value[3].value : null,
          };
        })
      : null;

  return {
    name: values.name,
    company: values.company?.value || null,
    type: values.type || "",
    currency: values.currency || "",
    percent: values.percent,

    forNew: !!values.carTypes.find((item) => item.value === ProductCarType.New),
    forAmp: forAmp,
    forElectric: values.carElectricType === ProductCarElectricType.Electric,

    period: values.period,
    carAge: forAmp ? values.carAge : null,
    amount: values.amount,

    discountPeriod: values.discountEnabled ? values.discountPeriod : null,
    discountPercent: values.discountEnabled ? values.discountPercent : null,

    extendedPercents: extendedPercentsValue,

    advancePaymentMin: values.advancePaymentMin,
    earlyPaymentMonthsMin: values.earlyPaymentMonthsMin,

    coefficientSpeedUp: isLeasing ? values.coefficientSpeedUp : null,
    redemptionPercent: isLeasing ? values.redemptionPercent : null,

    paymentType: values.paymentType,
    collateralType: values.collateralType,
    kaskoRequired: values.kaskoRequired,

    conditions: values.conditions,
    generations: values.generations.map((item) => item.value),
  };
}

export const createFieldExtendedPercentsConfig = (
  withDiscount: boolean
): FieldConfigUnion => {
  const result: FieldConfigUnion = {
    name: "",
    type: "REPEATER",
    label: "Процентные ставки",
    meta: {
      view: "TABLE",
    },
    fields: [
      {
        name: "minAge",
        type: "NUMBER",
        label: "Возраст, от",
      },
      {
        name: "maxAge",
        type: "NUMBER",
        label: "Возраст, до",
      },
      {
        name: "percent",
        type: "NUMBER",
        label: "Процентная ставка, %",
      },
    ],
  };

  if (withDiscount) {
    result.fields.push({
      name: "discountPercent",
      type: "NUMBER",
      label: "Льготная процентная ставка, %",
    });
  }

  return result;
};
