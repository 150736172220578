import { ColumnDefinition } from "@tager/admin-ui";

import { getCompaniesUpdateUrl } from "@/modules/companies/links";

import { CompanyInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<CompanyInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "80px", textAlign: "center" },
    headStyle: { width: "80px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Имя",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: getCompaniesUpdateUrl(row.id),
      },
    }),
  },
  {
    id: 3,
    name: "Логотип",
    field: "logo",
    type: "image",
    width: "200px",
  },
  {
    id: 4,
    name: "Телефон",
    field: "phone",
    width: "200px",
  },
  {
    id: 5,
    name: "E-Mail",
    field: "email",
    width: "200px",
  },
  {
    id: 6,
    name: "",
    field: "relations",
    width: "180px",
  },
  {
    id: 7,
    name: "Действия",
    field: "actions",
    width: "101px",
  },
];
