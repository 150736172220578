export function getCompaniesCreateUrl() {
  return "/companies/create";
}

export function getCompaniesUpdateUrl(id: number) {
  return "/companies/" + id;
}

export function getCompaniesListUrl() {
  return "/companies";
}
