import { computed, ComputedRef, ref, Ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isEqual, pick } from "lodash";

import {
  FilterTagType,
  getFilterParamAsNumber,
  getFilterParamAsString,
  getFilterParams,
  OptionType,
} from "@tager/admin-ui";
import { isNotNullish, Nullable, useResource } from "@tager/admin-services";

import { getCompaniesList } from "@/modules/companies/requests";
import {
  getProductTypeLabel,
  ProductCarType,
  ProductCarTypeOptions,
  ProductType,
  ProductTypeOptions,
} from "@/modules/products/enums";

interface Params {
  fetchEntityList: () => void;
}

interface State {
  companyFilter: Ref<Nullable<OptionType<number>>>;
  companyFilterOptions: Ref<Array<OptionType<number>>>;
  typeFilter: Ref<Nullable<ProductType>>;
  carTypeFilter: Ref<Nullable<ProductCarType>>;

  filterParams: ComputedRef<Record<string, string | string[]>>;
  tags: ComputedRef<FilterTagType[]>;
  tagRemovalHandler(event: FilterTagType): void;

  load: () => void;
  dataLoading: Ref<boolean>;
}

enum FilterTypes {
  Company = "company",
  Type = "type",
  CarType = "carType",
}

export function useAdvancedSearch({ fetchEntityList }: Params): State {
  const route = useRoute();
  const router = useRouter();

  const [
    fetchCompanies,
    { data: companiesList, loading: companiesListLoading },
  ] = useResource({
    fetchResource: () => getCompaniesList(),
    initialValue: [],
    resourceName: "Companies List",
  });

  const load = () => fetchCompanies();

  const companyFilterOptions = computed<Array<OptionType<number>>>(() => {
    return companiesList.value.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
  });

  const initialCompanyFilter = computed<Nullable<OptionType<number>>>(
    () =>
      companyFilterOptions.value.find(
        (item) =>
          item.value ===
          getFilterParamAsNumber(route.query, FilterTypes.Company)
      ) ?? null
  );
  const companyFilter = ref<Nullable<OptionType<number>>>(
    initialCompanyFilter.value
  );
  watch(initialCompanyFilter, () => {
    companyFilter.value = initialCompanyFilter.value;
  });

  const initialTypeFilter = computed<Nullable<ProductType>>(
    () =>
      ProductTypeOptions.find(
        (item) =>
          item.value === getFilterParamAsString(route.query, FilterTypes.Type)
      )?.value ?? null
  );
  const typeFilter = ref<Nullable<ProductType>>(initialTypeFilter.value);
  watch(initialTypeFilter, () => {
    typeFilter.value = initialTypeFilter.value;
  });

  const initialCarTypeFilter = computed<Nullable<ProductCarType>>(
    () =>
      ProductCarTypeOptions.find(
        (item) =>
          item.value ===
          getFilterParamAsString(route.query, FilterTypes.CarType)
      )?.value ?? null
  );
  const carTypeFilter = ref<Nullable<ProductCarType>>(
    initialCarTypeFilter.value
  );
  watch(initialCarTypeFilter, () => {
    carTypeFilter.value = initialCarTypeFilter.value;
  });

  /** Params **/

  const filterParams = computed(() => {
    return getFilterParams({
      [FilterTypes.Company]: companyFilter.value
        ? companyFilter.value.value
        : "",
      [FilterTypes.Type]: typeFilter.value ? String(typeFilter.value) : "",
      [FilterTypes.CarType]: carTypeFilter.value
        ? String(carTypeFilter.value)
        : "",
    });
  });

  const tagRemovalHandler = (event: FilterTagType): void => {
    if (event.name === FilterTypes.Company) {
      companyFilter.value = null;
    }
    if (event.name === FilterTypes.Type) {
      typeFilter.value = null;
    }
    if (event.name === FilterTypes.CarType) {
      carTypeFilter.value = null;
    }
  };

  /** Tags **/

  const tags = computed<FilterTagType[]>(() =>
    [
      companyFilter.value && companyFilter.value.value
        ? {
            value: String(companyFilter.value.value),
            label: companyFilter.value.label,
            name: FilterTypes.Company,
            title: "Компания",
          }
        : null,
      typeFilter.value && typeFilter.value
        ? {
            value: String(typeFilter.value),
            label: getProductTypeLabel(typeFilter.value),
            name: FilterTypes.Type,
            title: "Тип",
          }
        : null,
      typeFilter.value && typeFilter.value
        ? {
            value: String(typeFilter.value),
            label: getProductTypeLabel(typeFilter.value),
            name: FilterTypes.CarType,
            title: "Авто",
          }
        : null,
    ].filter(isNotNullish)
  );

  watch([filterParams, companiesListLoading], () => {
    if (companiesListLoading.value) return;

    const newQuery = {
      ...pick(route.query, ["query", "pageNumber", "sort"]),
      ...filterParams.value,
    };

    if (!isEqual(route.query, newQuery)) {
      router.replace({ query: newQuery });
    }

    setTimeout(fetchEntityList);
  });

  return {
    companyFilter,
    companyFilterOptions,
    typeFilter,
    carTypeFilter,
    dataLoading: companiesListLoading,
    filterParams,
    tags,
    tagRemovalHandler,

    load,
  };
}
