import { RouteRecordRaw } from "vue-router";

import ProductsList from "@/modules/products/ProductsList";
import ProductsForm from "@/modules/products/ProductsForm";
import { LINKS } from "@/constants/links";

export const PRODUCT_LIST_ROUTE: RouteRecordRaw = {
  path: "/products",
  component: ProductsList,
  name: "Продукты",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.PRODUCTS],
  },
};

export const PRODUCT_FORM_ROUTE: RouteRecordRaw = {
  path: "/products/:id",
  component: ProductsForm,
  name: "Форма продукта",
  meta: {
    getBreadcrumbs: (route) => [
      LINKS.HOME,
      LINKS.PRODUCTS,
      {
        url: route.path,
        text: "Форма продукта",
      },
    ],
  },
};
