import { LinkType } from "@tager/admin-ui";
import { getAdminListUrl, getRoleListUrl } from "@tager/admin-administrators";

import { getCompaniesListUrl } from "@/modules/companies/links";
import { getProductsListUrl } from "@/modules/products/links";

type LinkKey =
  | "HOME"
  | "COMPANIES"
  | "PRODUCTS"
  | "ADMIN_ROLES"
  | "ADMIN_ADMINS";

export const LINKS: Record<LinkKey, LinkType> = {
  HOME: {
    url: "/",
    text: "Главная",
  },
  PRODUCTS: {
    url: getProductsListUrl(),
    text: "Продукты",
  },
  COMPANIES: {
    url: getCompaniesListUrl(),
    text: "Компании",
  },
  ADMIN_ROLES: {
    url: getRoleListUrl(),
    text: "Роли",
  },
  ADMIN_ADMINS: {
    url: getAdminListUrl(),
    text: "Администраторы",
  },
};
