import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row-cols-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormFieldOptionsSwitcher = _resolveComponent("FormFieldOptionsSwitcher")!
  const _component_AdvancedSearch = _resolveComponent("AdvancedSearch")!

  return (_openBlock(), _createBlock(_component_AdvancedSearch, {
    tags: _ctx.tags,
    "onClick:tag": _ctx.tagRemovalHandler
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FormFieldSelect, {
          value: _ctx.companyFilter,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.companyFilter) = $event)),
          label: "Компания:",
          name: "companyFilter",
          searchable: true,
          options: _ctx.companyFilterOptions,
          "no-error-padding": true
        }, null, 8, ["value", "options"]),
        _createVNode(_component_FormFieldOptionsSwitcher, {
          value: _ctx.typeFilter,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.typeFilter) = $event)),
          label: "Тип:",
          name: "typeFilter",
          options: _ctx.ProductTypeOptions,
          "no-error-padding": true,
          nullable: true
        }, null, 8, ["value", "options"]),
        _createVNode(_component_FormFieldOptionsSwitcher, {
          value: _ctx.carTypeFilter,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.carTypeFilter) = $event)),
          label: "Авто:",
          name: "carTypeFilter",
          options: _ctx.ProductCarTypeOptions,
          "no-error-padding": true,
          nullable: true
        }, null, 8, ["value", "options"])
      ])
    ]),
    _: 1
  }, 8, ["tags", "onClick:tag"]))
}