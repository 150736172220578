import { request, ResponseBody } from "@tager/admin-services";

import {
  CompanyFullInterface,
  CompanyInterface,
} from "@/modules/companies/typings";

export interface CountData {
  count: number;
}

export function getCompaniesCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/companies/count" });
}

export function getCompaniesList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<CompanyInterface>>> {
  return request.get({ path: "/admin/companies", params });
}

export function getCompany(
  id: string | number
): Promise<ResponseBody<CompanyFullInterface>> {
  return request.get({ path: `/admin/companies/${id}` });
}

export interface CompanySavePayload {
  name: string | null;
  email: string | null;
  phone: string | null;
  logo: string | null;
}

export function updateCompany(
    id: number | string,
    payload: CompanySavePayload
): Promise<ResponseBody<CompanyFullInterface>> {
    return request.put({
        path: `/admin/companies/${id}`,
        body: payload,
    });
}
export function createCompany(
    payload: CompanySavePayload
): Promise<ResponseBody<CompanyFullInterface>> {
    return request.post({
        path: `/admin/companies`,
        body: payload,
    });
}

export function deleteCompany(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({
    path: `/admin/companies/${id}`,
  });
}
