import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldFileInput = _resolveComponent("FormFieldFileInput")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: _ctx.pageTitle,
    "is-content-loading": _ctx.isContentLoading
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "is-creation": _ctx.isCreation,
        "can-create-another": _ctx.isCreation,
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        onSubmit: _ctx.submitForm
      }, null, 8, ["is-creation", "can-create-another", "back-href", "is-submitting", "onSubmit"])
    ]),
    default: _withCtx(() => [
      (!_ctx.isContentLoading)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            novalidate: "",
            onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_component_FormField, {
              value: _ctx.values.name,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.name) = $event)),
              name: "name",
              label: "Имя",
              error: _ctx.errors.name
            }, null, 8, ["value", "error"]),
            _createVNode(_component_FormFieldFileInput, {
              value: _ctx.values.logo,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.logo) = $event)),
              name: "logo",
              label: "Логотип",
              error: _ctx.errors.logo
            }, null, 8, ["value", "error"]),
            _createVNode(_component_FormField, {
              value: _ctx.values.email,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.email) = $event)),
              name: "email",
              label: "E-Mail",
              error: _ctx.errors.email
            }, null, 8, ["value", "error"]),
            _createVNode(_component_FormField, {
              value: _ctx.values.phone,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.phone) = $event)),
              name: "phone",
              label: "Телефон",
              error: _ctx.errors.phone
            }, null, 8, ["value", "error"])
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading"]))
}