import { createId, Nullable } from "@tager/admin-services";
import { SingleFileInputValueType } from "@tager/admin-ui";

import { CompanyFullInterface } from "@/modules/companies/typings";
import { CompanySavePayload } from "@/modules/companies/requests";

export interface FormValues {
  name: string | null;
  logo: Nullable<SingleFileInputValueType>;
  phone: string | null;
  email: string | null;
}

export function convertCompanyToFormValues(
  model: CompanyFullInterface | null
): FormValues {
  return {
    name: model ? model.name : null,
    phone: model ? model.phone : null,
    email: model ? model.email : null,
    logo: model?.logo ? { id: createId(), file: model.logo } : null,
  };
}

export function convertFormValuesToCompanySavePayload(
  values: FormValues
): CompanySavePayload {
  return {
    name: values.name,
    email: values.email,
    phone: values.phone,
    logo: values.logo?.file?.id || null,
  };
}
