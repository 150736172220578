import { OptionType } from "@tager/admin-ui";

export enum ProductType {
  Credit = "CREDIT",
  Leasing = "LEASING",
}

export function getProductTypeLabel(value: ProductType): string {
  switch (value) {
    case ProductType.Credit:
      return "Кредит";
    case ProductType.Leasing:
      return "Лизинг";
    default:
      return "Неизвестный";
  }
}

export const ProductTypeOptions: Array<OptionType<ProductType>> = [
  {
    value: ProductType.Credit,
    label: getProductTypeLabel(ProductType.Credit),
  },
  {
    value: ProductType.Leasing,
    label: getProductTypeLabel(ProductType.Leasing),
  },
];

export enum ProductCarType {
  New = "NEW",
  Amp = "AMP",
}

export function getProductCarTypeLabel(value: ProductCarType): string {
  switch (value) {
    case ProductCarType.New:
      return "Новые автомобили";
    case ProductCarType.Amp:
      return "Автомобили с пробегом";
    default:
      return "Неизвестный";
  }
}

export const ProductCarTypeOptions: Array<OptionType<ProductCarType>> = [
  {
    value: ProductCarType.New,
    label: getProductCarTypeLabel(ProductCarType.New),
  },
  {
    value: ProductCarType.Amp,
    label: getProductCarTypeLabel(ProductCarType.Amp),
  },
];

export enum ProductCarElectricType {
  All = "ALL",
  Electric = "ELECTRIC",
}

export function getProductCarElectricTypeLabel(
  value: ProductCarElectricType
): string {
  switch (value) {
    case ProductCarElectricType.All:
      return "Все автомобили";
    case ProductCarElectricType.Electric:
      return "Электромобили";
    default:
      return "Неизвестный";
  }
}

export const ProductCarElectricTypeOptions: Array<
  OptionType<ProductCarElectricType>
> = [
  {
    value: ProductCarElectricType.All,
    label: getProductCarElectricTypeLabel(ProductCarElectricType.All),
  },
  {
    value: ProductCarElectricType.Electric,
    label: getProductCarElectricTypeLabel(ProductCarElectricType.Electric),
  },
];

export enum Currency {
  BYN = "BYN",
  RUB = "RUB",
  USD = "USD",
  EUR = "EUR",
  UAH = "UAH",
  CNY = "CNY",
}
export const CurrencyOptions: Array<OptionType<Currency>> = [
  {
    value: Currency.BYN,
    label: Currency.BYN,
  },
  {
    value: Currency.RUB,
    label: Currency.RUB,
  },
  {
    value: Currency.USD,
    label: Currency.USD,
  },
  {
    value: Currency.EUR,
    label: Currency.EUR,
  },
  {
    value: Currency.UAH,
    label: Currency.UAH,
  },
  {
    value: Currency.CNY,
    label: Currency.CNY,
  },
];

export enum PaymentType {
  Annuity = "ANNUITY",
  Differentiated = "DIFFERENTIATED",
}

export function getPaymentTypeLabel(value: PaymentType): string {
  switch (value) {
    case PaymentType.Annuity:
      return "Аннуитетный платёж";
    case PaymentType.Differentiated:
      return "Дифференцированный платёж";
    default:
      return "Неизвестный";
  }
}

export const ProductPaymentTypeOptions: Array<OptionType<PaymentType>> = [
  {
    value: PaymentType.Annuity,
    label: getPaymentTypeLabel(PaymentType.Annuity),
  },
  {
    value: PaymentType.Differentiated,
    label: getPaymentTypeLabel(PaymentType.Differentiated),
  },
];

export enum CollateralType {
  CarAndSurety = "CAR_AND_SURETY",
  Car = "CAR",
  Surety = "SURETY",
}

export function getCollateralTypeLabel(value: CollateralType | null): string {
  switch (value) {
    case CollateralType.CarAndSurety:
      return "Авто в залог и поручительство";
    case CollateralType.Car:
      return "Авто в залог";
    case CollateralType.Surety:
      return "Поручительство";
    default:
      return "Без обеспечения";
  }
}

export const ProductCollateralTypeOptions: Array<
  OptionType<CollateralType | null>
> = [
  {
    value: CollateralType.CarAndSurety,
    label: getCollateralTypeLabel(CollateralType.CarAndSurety),
  },
  {
    value: CollateralType.Car,
    label: getCollateralTypeLabel(CollateralType.Car),
  },
  {
    value: CollateralType.Surety,
    label: getCollateralTypeLabel(CollateralType.Surety),
  },
  {
    value: null,
    label: getCollateralTypeLabel(null),
  },
];
