import { Nullable, request, ResponseBody } from "@tager/admin-services";
import { MinMaxValue } from "@tager/admin-ui";

import {
  ProductInterface,
  ProductFullInterface,
} from "@/modules/products/typings";
import { CollateralType, PaymentType } from "@/modules/products/enums";

export interface CountData {
  count: number;
}

export function getProductsCatalog(): Promise<
  ResponseBody<Array<{ id: number; name: string }>>
> {
  return request.get({ path: "/admin/products/catalog" });
}

export function getProductsCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/products/count" });
}

export function getProductsList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<ProductInterface>>> {
  return request.get({ path: "/admin/products", params });
}

export function getProduct(
  id: string | number
): Promise<ResponseBody<ProductFullInterface>> {
  return request.get({ path: `/admin/products/${id}` });
}

export interface ProductSavePayload {
  name: string | null;
  company: number | null;
  type: string;
  currency: string;
  percent: number | null;

  discountPeriod: number | null;
  discountPercent: number | null;

  forNew: boolean;
  forAmp: boolean;
  forElectric: boolean;

  extendedPercents: Nullable<
    Array<{
      minAge: number | null;
      maxAge: number | null;
      percent: number | null;
      discountPercent: number | null;
    }>
  >;

  period: MinMaxValue;
  carAge: MinMaxValue;
  amount: MinMaxValue;

  advancePaymentMin: number | null;
  earlyPaymentMonthsMin: number | null;
  coefficientSpeedUp: number | null;
  redemptionPercent: number | null;

  paymentType: PaymentType;
  collateralType: CollateralType;
  kaskoRequired: boolean;

  conditions: string;
  generations: number[];
}

export function updateProduct(
  id: number | string,
  payload: ProductSavePayload
): Promise<ResponseBody<ProductFullInterface>> {
  return request.put({
    path: `/admin/products/${id}`,
    body: payload,
  });
}
export function createProduct(
  payload: ProductSavePayload
): Promise<ResponseBody<ProductFullInterface>> {
  return request.post({
    path: `/admin/products`,
    body: payload,
  });
}

export function deleteProduct(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({
    path: `/admin/products/${id}`,
  });
}
