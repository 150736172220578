<template>
  <AdvancedSearch :tags="tags" @click:tag="tagRemovalHandler">
    <div class="row-cols-3">
      <FormFieldSelect
        v-model:value="companyFilter"
        label="Компания:"
        name="companyFilter"
        :searchable="true"
        :options="companyFilterOptions"
        :no-error-padding="true"
      />
      <FormFieldOptionsSwitcher
        v-model:value="typeFilter"
        label="Тип:"
        name="typeFilter"
        :options="ProductTypeOptions"
        :no-error-padding="true"
        :nullable="true"
      />
      <FormFieldOptionsSwitcher
        v-model:value="carTypeFilter"
        label="Авто:"
        name="carTypeFilter"
        :options="ProductCarTypeOptions"
        :no-error-padding="true"
        :nullable="true"
      />
    </div>
  </AdvancedSearch>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, watch } from "vue";

import {
  AdvancedSearch,
  FormFieldSelect,
  FormFieldOptionsSwitcher,
} from "@tager/admin-ui";

import { useAdvancedSearch } from "@/modules/products/ProductsList/ProductsList.search";
import {
  ProductTypeOptions,
  ProductCarTypeOptions,
} from "@/modules/products/enums";

export default defineComponent({
  name: "ProductsListSearch",
  components: {
    AdvancedSearch,
    FormFieldSelect,
    FormFieldOptionsSwitcher,
  },
  props: {
    fetchData: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  emits: ["update:loading", "update:value"],
  setup(props, context) {
    const {
      load,
      typeFilter,
      carTypeFilter,
      companyFilterOptions,
      companyFilter,
      filterParams,
      tags,
      tagRemovalHandler,
      dataLoading,
    } = useAdvancedSearch({ fetchEntityList: props.fetchData });

    onMounted(() => {
      context.emit("update:loading", dataLoading.value);
      load();
    });

    watch(dataLoading, () => {
      context.emit("update:loading", dataLoading.value);
    });

    watch(filterParams, () => context.emit("update:value", filterParams.value));
    onMounted(() => context.emit("update:value", filterParams.value));

    return {
      typeFilter,
      carTypeFilter,
      companyFilterOptions,
      companyFilter,
      filterParams,
      tags,
      tagRemovalHandler,
      ProductTypeOptions,
      ProductCarTypeOptions,
    };
  },
});
</script>
