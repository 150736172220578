export function getProductsCreateUrl() {
  return "/products/create";
}

export function getProductsUpdateUrl(id: number) {
  return "/products/" + id;
}

export function getProductsListUrl() {
  return "/products";
}
